import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/diaper-rash/diaper-rash-product-front.webp";
import backImage from "images/baby/diaper-rash/diaper-rash-product-back.webp";
import frontZoomImage from "images/baby/diaper-rash/diaper-rash-product-front-zoom.webp";
import backZoomImage from "images/baby/diaper-rash/diaper-rash-product-back-zoom.webp";

import img2 from "images/baby/diaper-rash/aquaphor-baby-3in1-rash-cream-clinical.webp";
import img3 from "images/baby/diaper-rash/diaper-rash-resource.webp";
import img4 from "images/baby/general-care/aquaphor-ointment-callout-footer.webp";
import img5 from "images/callouts/cout-diaperpaste-footer.webp";

import "./aquaphordiaperrashcream.scss";

function AquaphorDiaperRashPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-diaperrash page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Baby 3-in-1 Diaper Rash Cream"
        pageDescription="Learn about Aquaphor Baby 3-in-1 Diaper Rash Cream"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#363084"
              categoryName="DIAPER RASh"
              categoryFontColor="#ffffff"
              productName="Aquaphor<sup>®</sup> Baby 3-in-1 Diaper Rash Cream"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold metioriteblue-text sub-title">
                  Helps treat mild to moderate diaper rash
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Baby 3-in-1 Diaper Rash Cream
                  </span>{" "}
                  is formulated to help protect babies' skin, provide soothing
                  relief, and effectively treat mild to moderate diaper rash.
                </p>
                <ul className="metiorite-bullets">
                  <li>
                    <span>
                      Creates an effective barrier and protects babies' skin all
                      night long
                    </span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Clinically demonstrated to improve diaper rash and
                      irritation<sup>1</sup>
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulated with 15% zinc oxide (skin protectant) for use
                      to prevent diaper rash or at the first signs
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold metioriteblue-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">15% zinc oxide</p>
                <ul className="metiorite-bullets">
                  <li>
                    <span>Skin protectant</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold metioriteblue-text sub-title">
                  Inactive ingredients
                </p>
                <p>
                  Mineral oil, glycerin, ceresin, polyglyceryl-4
                  diisostearate/polyhydroxystearate/ sebacate, magnesium
                  stearate, panthenol, water
                </p>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold metioriteblue-text sub-title">
                    Ideal for sensitive skin
                  </p>
                  <ul className="checkmarks metioriteblue">
                    <li>
                      <span>HYPOALLERGENIC</span>
                    </li>
                    <li>
                      <span>ODOR-FREE</span>
                    </li>
                    <li>
                      <span>TALC-FREE</span>
                    </li>
                    <li>
                      <span>PRESERVATIVE-FREE</span>
                    </li>
                  </ul>
                </div>
                <p className="footnotes">
                  <span className="font-bold">Reference: 1.</span> Data on file.
                  Beiersdorf Inc.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="CLINICAL RESULTS"
              productThemeColor="baby-metiorite-blue"
              id="clinicalresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Graph with improvement in diaper rash severity and symptoms after 1 week"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> A
                    single-center clinical trial conducted to assess the
                    efficacy and tolerability of Aquaphor Baby{" "}
                    <nobr>3-in-1</nobr> Diaper Rash Cream when used on infants
                    (N=18) over the course of 1 week. Subjects were assessed at
                    baseline, 6 hours after a single use, and after 1 week of
                    regular use.
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant improvement in mean clinical
                    grading scores compared to baseline.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Statistically <br className="show-desktop" />
                    significant <br className="show-desktop" />
                    improvement in <br className="show-desktop" />
                    diaper rash severity <br className="show-desktop" />
                    and symptoms after <br className="show-desktop" /> 1 week
                    <sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource purple">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "aquaphor-baby-3-in-1-diaper-rash-cream",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation <br className="show-desktop" />{" "}
                    with this resource
                  </p>
                  <p className="reg-text">
                    Review the clinical data for diaper rash cream with parents.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img3}
                  alt="Clinical Data for Diaper Rash Cream"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img4}
                  imgAlt="AQUAPHOR® BABY HEALING OINTMENT"
                  caption="Helps prevent diaper rash with <br class='show-desktop' /> every diaper change"
                  buttonText="AQUAPHOR<sup>®</sup> BABY HEALING OINTMENT"
                  buttonUrl="/baby/generalcare/aquaphorbabyhealingointment"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img5}
                  imgAlt="AQUAPHOR® BABY HEALING PASTE"
                  caption="Helps soothe babies’ moderate to <br class='show-desktop' /> severe diaper rash"
                  buttonText="AQUAPHOR<sup>®</sup> BABY HEALING PASTE"
                  buttonUrl="/baby/diaperrash/aquaphordiaperrashpaste"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AquaphorDiaperRashPage;
